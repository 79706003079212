.react-pdf__Page__canvas{
    width: 152px !important;
    height: 211px !important;
    // margin-left: -60px !important;
    // margin-top: -30px !important;
}
.react-pdf__Page{
    position: inherit !important;
    // height:500px !important
}
.mmm .react-pdf__Page__canvas{
    width: 875px !important;
    height: 1064px !important;
    margin-left: -60px !important;
    // margin-top: -30px !important;
}
.truckimagemodalpdf .react-pdf__Page__canvas{
    width:600px !important;
    height:100% !important
}
.truckimage-modal .ant-modal-content{
    width:600px !important;
   // height:400px !important

}