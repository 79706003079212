.ant-input-group-addon {
    border-radius: 50px;
    margin-left: 100px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: -27px !important;
    padding: 0 !important;
    border: 0 !important;
    background: none !important;
}

.customSearch .ant-input-group .ant-input {
    border-radius: 50px;
    background: #f4f5f9 !important;
    padding: 9.5px;
}

.ant-input {
    font-size: 12px !important;
}

.ant-input-search-button {
    z-index: 1 !important;
}

.anticon > .anticon-search {
    padding: 3px;
}
.customSearch .ant-btn-primary {
    border-radius: 50% !important;
    background-color: #5078f2 !important;
    height: 40px !important;
    width: 40px !important;
    margin-left: 3px !important;
}

.border_right {
    border-right: 1px solid #b3b8c1;
    padding: 0px 10px !important;
}

.franchise .css-2b097c-container .css-yk16xz-control {
    border-color: transparent !important;
}

.franchise
    .css-2b097c-container
    .css-yk16xz-control
    .css-g1d714-ValueContainer
    .css-1wa3eu0-placeholder {
    color: #3a4960 !important;
    padding-top: 11px !important;
    padding-bottom: 13px !important;
}

.franchise
    .css-2b097c-container
    .css-yk16xz-control
    .css-1hb7zxy-IndicatorsContainer
    .css-1okebmr-indicatorSeparator {
    display: none !important;
}

.franchise
    .css-2b097c-container
    .css-yk16xz-control
    .css-1hb7zxy-IndicatorsContainer
    .css-tlfecz-indicatorContainer {
    color: #3a4960 !important;
    margin-top: 3px !important;
}

.franchise
    .css-2b097c-container
    .css-yk16xz-control
    .css-1hwfws3
    .css-1wa3eu0-placeholder {
    color: #3a4960 !important;
    padding-top: 11px !important;
    padding-bottom: 13px !important;
}

.franchise
    .css-2b097c-container
    .css-yk16xz-control
    .css-1wy0on6
    .css-1okebmr-indicatorSeparator {
    display: none !important;
}

.franchise
    .css-2b097c-container
    .css-yk16xz-control
    .css-1wy0on6
    .css-tlfecz-indicatorContainer {
    color: #3a4960 !important;
    margin-top: 3px !important;
}

.css-1laa021-a11yText {
    border-color: transparent !important;
}

.pending_label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5078f2;
    text-transform: uppercase;
}

.pending_div {
    display: flex;
    align-items: center;
}

.pendingDot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f2994a;
    margin-right: 8px;
}

.requestDot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f2c94c;
    margin-right: 8px;
}

.generateDot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #219653;
    margin-right: 8px;
}

.partialDot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f2994a;
    margin-right: 8px;
}
.returnDot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #e13440;
    margin-right: 8px;
}

.purchase_content {
    height: auto;
    width: 100%;
    background-color: #fafafa;
    padding: 20px 0px 20px 10px;
}

.purchase_label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3a4960;
}

.purchase_sub {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7a7a7a;
}

.purchase_value {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3a4960;
}
.purchase_amt_sub {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #7a7a7a;
}
.purchase_amount {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #27ae60;
}

.purchase_table table {
    width: 96% !important;
}

.purchase_table table thead tr {
    background-color: #ffffff !important;
    box-shadow: 1px 4px 8px #e0e0e0;
}

.purchase_table table thead th {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px;
    color: #3a4960 !important;
    padding: 15px;
}

.table_image {
    height: 70px;
    width: auto;
}

.purchase_item {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3a4960;
}

.purchase_id {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #7a7a7a;
}

.economy {
    background-color: #f2c94c;
    color: #444444;
    padding: 2px;
    font-size: 10px;
}

.discount {
    color: #e13440;
    font-size: 12px;
}

.purchase_unit {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #3a4960;
    width: 80px;
}

.purchase_unit_true {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #3a4960;
    width: 80px;
    border: 2px solid #5078f2;
}

.purchase_unit_false {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #3a4960;
    width: 80px;
    border: 2px solid red;
}

.purchase_history_Qty {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #3a4960;
}

.purchase_history_recieveQty {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: red;
}

.strike_data {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: line-through;
    color: #3a4960;
}

.purchase_remarks {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #3a4960;
}

.purchase_input .form-control:disabled,
.form-control[readonly] {
    background-color: white !important;
    // opacity: 1;
}

.franchisePagination .ant-pagination-item-active a {
    background-color: #5078f2 !important;
    padding-bottom: 2px;
}

.purchase_picker .ant-picker {
    width: 200px !important;
    border-color: transparent !important;
}

// .purchase_picker .ant-picker .ant-picker-suffix {
//     display: none !important;
// }

.purchase_picker .ant-picker .ant-picker-input .ant-picker-suffix {
    visibility: hidden !important;
}

.purchase_picker .ant-picker .ant-picker-input > input::placeholder {
    color: #3a4960 !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.purchase_picker .ant-picker .ant-picker-input {
    background-image: url("../../../src/images/franchise-chevdown.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-size: 12px !important;
}

.purchase_picker .ant-picker .ant-picker-input .ant-picker-clear {
    right: 15% !important;
}

.grnModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.grnLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    color: #3a4960;
}

.grn_sub {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #3a4960;
}

.grnModalContent .ant-modal-close {
    display: none;
}

.grnModalContent .ant-modal-content {
    border-radius: 20px !important;
}

.authenticationModalContent .ant-modal-content {
    border-radius: 30px !important;
    width: 600px !important;
}

.authenticationModalContent .ant-modal-close:active {
    border-color: transparent !important;
    outline: transparent !important;
}

.authenticationModal {
    display: flex;
}

.sub_div {
    display: flex;
    flex-direction: column;
}

.auth_content {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #3a4960;
}

.auth_sub {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #3a4960;
}

.modal_highlights {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: #3a4960;
}
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }
