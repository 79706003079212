.rangePickerNew {
  padding: 10px;
  background-color: #eee !important;
  border-radius: 50px;
  width: fit-content;
}
.sales-overview {
  border-left: 6px solid #5078f2;
  height: 75px;
  margin-top: 30px;
  padding-left: 12px;
  width: 26%;
}
.sales-overview-text {
  font-size: 31px;
  font-weight: 700;
  line-height: 48px;
}
.cancel-overview {
  border-left: 6px solid #FF6871;
  height: 75px;
  margin-top: 30px;
  padding-left: 12px;
  width: 26%;
}
.cancellation-data {
  background-color: #f8f8f8;
  align-items: center;
  justify-content: center;
}
.cancel-overview1 {
  border-left: 6px solid #FF6871;
  height: 100%;
  width: 26%;
  margin-top: 30px;
  text-align: center;
}
.button_refresh {
  background-color: #5078f2;
  color: white;
  width: 203px !important;
  height: 48px;
  border-radius: 8px;
  margin-top: 10px;
}
.sales-overview-value {
  color: #7a859a;
  font-weight: 500;
  font-size: 16px;
}
