.confirm_button {
  border-radius: 4px;
  background-color: #5078f2 !important;
  width: 236px;
  height: 43px;
  padding: 12px 22px;
  text-align: center;
  color: white !important;
  margin-top: 41px;
}

.confirm_button:disabled {
  background-color: #b4b4b4 !important;
}
// .focusInput {
//   width: "74px";
//   height: "36px";
//   margin: "4px";
//   border-color: blue !important;
//   border-top: "0px" !important;
//   border-left: "0px" !important;
//   border-right: "0px" !important;
//   outline: "none";
// }
