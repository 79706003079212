.input-group {
    width: 50%;
    margin-left: 210px;
    /* margin-top: 100px; */
  }
  
  .bottom-text {
    text-align: center;
    margin-top: 100px;
  }
  
  .file {
    margin-left: 210px;
  }
  
  .success_msg {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding: 10px;
  }
  
  .file_name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #757575;
    margin-left: 26px;
  }
  
  .error_msg {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 210px;
    color: red;
  }
  
  .center_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .modal_content {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
    color: #3a4960;
  }
  
  .customer_label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #3a4960;
  }
  
  .customer_updates {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #7a7a7a;
  }
  
  .priceSearch .ant-input-group .ant-input {
    padding: 10.5px;
    width: 290px !important;
  }
  .priceSearch .ant-btn-primary {
    background-color: #5078f2 !important;
    height: 40px !important;
    width: 40px !important;
    margin-left: 3px !important;
  }
  
  .pricePicker .ant-picker {
    border-radius: 50px !important;
    background-color: #f5f7fa;
  }
  
  .pricePicker .ant-picker .ant-picker-input {
    padding: 3px !important;
  }
  
  .pricePicker .ant-picker .ant-picker-input .ant-picker-suffix {
    color: black !important;
  }
  
  .pricePicker .ant-picker-range .ant-picker-suffix {
    color: black !important;
  }
  
  .validity_label {
    font-family: Inter;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: #3a4960;
  }
  
  .price_updates {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #3a4960;
  }
  
  .material_id {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #3a4960;
  }
  
  .material_name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    color: #3a4960;
  }
  
  .customTextfield input {
    border: unset;
  }
  .customTextfield input:focus {
    outline: unset;
  }
  .customTextfield {
    border: 1px solid #DADADA;
    padding: 8px;
    width: 120px;
  }
  